// https://github.com/vercel/next.js/blob/master/examples/with-google-analytics/pages/_app.js

import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { DefaultSeo } from 'next-seo'

import { useWeb3React, Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import StoreProvider from 'web/lib/store'

import * as gtag from 'web/lib/google-tag-manager'

import 'web/styles/globals.css'
import DebugProvider from 'web/lib/debug-provider'
import { SWRConfig } from 'swr'
import useEnvironment from 'web/hooks/useEnvironment'
import { captureException } from '@sentry/nextjs'
import NonceProvider from 'web/lib/nonce-provider'

function getLibrary(provider, connector) {
  console.log('getLibrary', { provider, connector })
  // if(process.env.CHAIN_ID.toString() == '31337'){
  //   return new ethers.providers.StaticJsonRpcProvider()
  // }
  // else {
  //   return new ethers.providers.AlchemyProvider(process.env.CHAIN_NAME, process.env.ALCHEMY_API_KEY)
  // }

  return new Web3Provider(provider)
}

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { isDev } = useEnvironment()

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const onSWRError = useCallback((err, key) => {
    // strip user acct from errors so they group together in Sentry
    if (typeof key == 'string') {
      key = key.replace(new RegExp(`\/a0x[a-zA-Z0-9]+`), '/:account')
    }

    console.error(`SWR ${key} =>`, err)
    captureException(new Error(`SWR ${key}`, {  cause: err }))
  }, [])

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <SWRConfig value={{
        provider: () => new Map(),
        dedupingInterval: 1000 * 2,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        onSuccess: (data, key, config) => {
          if (isDev && data != undefined) {
            // console.log('%c Oh my heavens! ');
            console.log(`%c SWR ${key} =>`, 'color: #bada55', data)
          }
        },
        onError: onSWRError
      }}>
        <StoreProvider>
          <NonceProvider>
            <DebugProvider>
              <DefaultSeo
                // dangerouslySetAllPagesToNoIndex={process.env.CHAIN_ID !== 1}
                // dangerouslySetAllPagesToNoFollow={process.env.CHAIN_ID !== 1}
                titleTemplate="%s ✧ GAWDS"
                defaultTitle="GAWDS"
                openGraph={{
                  title: 'GAWDS',
                  description: 'The first generative holographic NFTs.',
                  type: 'website',
                  url: 'https://www.gawds.xyz',
                  site_name: 'GAWDS',
                  images: [
                    {
                      url: `${process.env.BASE_URL}/images/social-card.png`,
                      width: 1000,
                      height: 500,
                      alt: '',
                    },
                  ],
                }}
                additionalLinkTags={[
                  { rel: 'apple-touch-icon', href: `${process.env.BASE_URL}/images/apple-touch-icons/152x152.png`, sizes: '152x152' },
                  { rel: 'apple-touch-icon', href: `${process.env.BASE_URL}/images/apple-touch-icons/120x120.png`, sizes: '120x120' },
                  { rel: 'apple-touch-icon', href: `${process.env.BASE_URL}/images/apple-touch-icons/76x76.png`, sizes: '76x76' },
                  { rel: 'apple-touch-icon', href: `${process.env.BASE_URL}/images/apple-touch-icons/60x60.png`, sizes: '60x60' },
                  { rel: 'apple-touch-icon', href: `${process.env.BASE_URL}/images/apple-touch-icons/120x120.png` }, // default
                ]}
                twitter={{
                  handle: '@cryptogawds',
                  site: '@cryptogawds',
                  cardType: 'summary_large_image',
                }}
              />
              <Component {...pageProps} />
            </DebugProvider>
          </NonceProvider>
        </StoreProvider>
      </SWRConfig>
    </Web3ReactProvider>
  )
}

export default MyApp
