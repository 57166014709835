import { createContext, Dispatch, useMemo, useReducer, useState } from "react";
import { StoreAction } from "./store";

export type DebugContextProps = {
  messages: string[],
};

const defaults: DebugContextProps = {
  messages: []
}

const reducer = (state: DebugContextProps, [action, payload]: [StoreAction, string]) => {
  switch (action) {
    case StoreAction.Update:
    case StoreAction.Add:
      return {
        ...state,
        messages: [payload, ...state.messages]
      }

    case StoreAction.Reset:
      return defaults

    default:
      return state
  }
}

export const DebugContext = createContext<[DebugContextProps, Dispatch<[StoreAction, string]>]>(undefined);

export default function DebugProvider({ children }) {
  const value = useReducer(reducer, defaults)

  return (
    <DebugContext.Provider value={value}>
      {children}
    </DebugContext.Provider>
  )
}
