// @ts-check

const BASE_URL = process.env.BASE_URL
const VERCEL_GIT_COMMIT_REF = process.env.VERCEL_GIT_COMMIT_REF
const VERCEL_URL = process.env.VERCEL_URL

const baseVercelUrl = `https://${VERCEL_URL}`
let baseUrl = BASE_URL || baseVercelUrl || 'https://localhost:3000'

let environment = 'development'
if (baseUrl.match(/(www|beta).gawds.xyz/)) {
    environment = 'production'
}

// If this isn't main branch, then we're definitely NOT on production
if (VERCEL_GIT_COMMIT_REF != undefined && VERCEL_GIT_COMMIT_REF != 'main' && VERCEL_URL != undefined) {
    baseUrl = baseVercelUrl
    environment = 'staging'
}

// Hack the isBeta field
const isBeta = process.env.IS_MAINNET_LIVE

module.exports = {
    baseUrl,
    environment,
    isDev: environment != 'production',
    isBeta
}