// https://github.com/vercel/next.js/blob/master/examples/with-google-tag-manager/lib/gtm.js
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if('gtag' in window) {
    // window.gtag('config', GA_TRACKING_ID, { page_path: url })

    window.gtag('event', 'page_view', {
      // send_to: GA_TRACKING_ID,
      page_path: url,
      // page_title: document.title,
      // page_location: location.href,
    })
  } else {
    console.warn('window.gtag not found...')
  }

}

export const event = ({ action, params }) => {
  window.gtag('event', action, params)
}