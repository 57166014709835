// https://dev.to/nazmifeeroz/using-usecontext-and-usestate-hooks-as-a-store-mnm
// https://reactjs.org/docs/hooks-reference.html#usecontext
// https://kentcdodds.com/blog/should-i-usestate-or-usereducer
// https://www.robinwieruch.de/react-usereducer-vs-usestate
// https://adamrackis.dev/redux-in-hooks/

import { createContext, useReducer, Dispatch } from 'react'
import { ethers, BigNumber, ContractTransaction, ContractReceipt } from 'ethers'
import { TamaGawdtchiManager } from './TamaGawdtchiManager'
import { Cult, Power } from './gawd'
import { PolygonTxnStatus } from 'web/components/TxnToast'

export enum StoreAction {
  Update = 'UPDATE',
  Reset = 'RESET',
  Delete = 'DELETE',
  Add = 'ADD'
}

export enum CultType {
  None = 0,
  Arcane = 1,
  Astral = 2,
  Terrene = 3,
  Unknown = 4
}

export enum TransactionStatus {
  Initial = 'Initial',
  Started = 'Started',
  Pending = 'Pending',
  Complete = 'Complete',
  Error = 'Error'
}

export interface TransactionData {
  status?: TransactionStatus
  quantity?: number
  txn?: ContractTransaction
  receipt?: ContractReceipt
}
const defaultTransactionStatus: TransactionData = {
  status: TransactionStatus.Initial,
  quantity: 1,
  txn: null,
  receipt: null
}

// see: "Tweaking our reducer" section of https://adamrackis.dev/redux-in-hooks/
const transactionStatusReducer = (state: TransactionData, [action, payload]:[StoreAction, TransactionData]) => {
  switch (action) {
    case StoreAction.Update:
      if (!TransactionStatus[payload.status]) {
        throw new Error(`Status must be one of: ${Object.keys(TransactionStatus)} (was ${payload.status})`)
      }

      return {
        ...state,
        ...payload,
      }
    case StoreAction.Reset:
      return defaultTransactionStatus
    default:
      throw new Error('Invalid action type when updating the transaction status')
  }
}

// TODO is there a better way to syncronize this initial context state with the server-side data
// pulled from `getStaticProps()`?  see loadContractData() in index.js
const defaultSupply = {
  saleStarted: false,
  max: '0',
  sold: '0',
  price: '0',
  block: null,
  subTotal: ethers.BigNumber.from(1),
}

// see: "Tweaking our reducer" section of https://adamrackis.dev/redux-in-hooks/
const supplyReducer = (state, [action, payload]) => {
  switch (action) {
    case 'UPDATE':
      return {
        ...state,
        ...payload,
      }
    default:
      throw new Error('Invalid action type when updating supply')
  }
}

const defaultWalletModalOpen = false
const connectWalletModalReducer = (state, [action]) => {
  switch (action) {
    case 'OPEN':
      return true
    case 'CLOSE':
      return false
    case 'TOGGLE':
      return !state
    default:
      throw new Error('Invalid action type when handling ConnectWallet dialog')
  }
}

export type PolygonToastData = {
  command: string
  hash: string
  status?: PolygonTxnStatus
  nonce?: string
  error?: string
}
export type PolygonTxnDataStore = {
  txns: PolygonToastData[]
}
const defaultPolygonTxns: PolygonTxnDataStore = {
  txns: []
}

const polygonTxnsReducer = (state: PolygonTxnDataStore, [action, payload]:[StoreAction, PolygonToastData]) => {
  switch (action) {
    case 'ADD':
      return {
        ...state,
        txns: [payload, ...state.txns]
      }
    case 'UPDATE':
      for (let i = 0; i < state.txns.length; i++) {
        if (state.txns[i].nonce == payload.nonce) {
          state.txns[i] = payload
          break
        }
      }
      return {
        ...state,
        txns: [...state.txns]
      }

      case 'DELETE':
        return {
          ...state,
          txns: state.txns.filter(txn => txn.nonce != payload.nonce)
        }

    case 'RESET':
      return defaultPolygonTxns
    default:
      return state
  }
}

export type GawchiDataStore = {
  gawds: Array<TamaGawdtchiManager>
}

const defaultGawdtchis: GawchiDataStore = {
  gawds: []
}

const gawdtchiReducer = (state:GawchiDataStore, [action, payload]:[StoreAction, TamaGawdtchiManager]) => {
  switch (action) {
    case StoreAction.Update:
      let insert = true
      for (let i = 0; i < state.gawds.length; i++) {
        if (state.gawds[i].gawdId() == payload.gawdId()) {
          state.gawds[i] = payload
          insert = false
          break
        }
      }
      return {
        ...state,
        gawds: insert ? [payload, ...state.gawds] : [...state.gawds]
      }

      case StoreAction.Delete:
        return {
          ...state,
          gawds: state.gawds.filter(gawdtchi => gawdtchi.gawdId != payload.gawdId)
        }

    case StoreAction.Reset:
      return defaultGawdtchis
    default:
      return state
  }
}

export type CultGameData = {
  lastEnergyUpdate?: Date
  totalGawdsOwned?: number
  lastGawdCountUpdate?: number
  totalHealthyGawds?: number
  nextRebaseBlock?: number
  lastRebaseBlock?: number
  boundAt?: number
  baseEnergyPerGawd?: number
  earlyAccessBoost?: number
  alignedPower?: Power
  alignedPowerBoost?: number
  alignedPowerEarnings?: number
  alignedGawdsByPowerCount?: number
  happyGawds?: number
  globalGawdHealth?: number
}

const defaultCultGame: CultGameData = {
  lastEnergyUpdate: new Date(0),
  totalGawdsOwned: 0,
  lastGawdCountUpdate: 0,
  totalHealthyGawds: 0,
  nextRebaseBlock: 0,
  lastRebaseBlock: 0,
  baseEnergyPerGawd: 1,
  earlyAccessBoost: 0,
  alignedPower: null,
  alignedPowerBoost: 0,
  alignedPowerEarnings: 0,
  alignedGawdsByPowerCount: 0,
  happyGawds: 0,
  globalGawdHealth: 0,
}

const cultReducer = (state:CultGameData, [action, payload]:[StoreAction, CultGameData]) => {
  switch (action) {
    case StoreAction.Update:
      // console.log("cultReducer UPDATE here", state, action, payload)
      return {
        ...state,
        ...payload,
      }
    case StoreAction.Reset:
      return defaultCultGame
    default:
      throw new Error('Invalid action type when updating cult')
  }
}

export type GlobalContent = {
  connectWalletModal?: [boolean, Dispatch<any>],
  transaction?: [TransactionData, Dispatch<[StoreAction, TransactionData]>],
  blockchain?: [any, any],
  polygonTxns: [PolygonTxnDataStore, Dispatch<[StoreAction, PolygonToastData]>],
  gawdtchis: [GawchiDataStore, Dispatch<[StoreAction, TamaGawdtchiManager]>],
  cultGame: [CultGameData, Dispatch<[StoreAction, CultGameData]>],
}

export const StoreContext = createContext<GlobalContent>(undefined)

const StoreProvider = ({ children }) => {
  const defaultGlobalStore: GlobalContent = {
    connectWalletModal: useReducer(connectWalletModalReducer, defaultWalletModalOpen),
    transaction: useReducer(transactionStatusReducer, defaultTransactionStatus),
    blockchain: useReducer(supplyReducer, defaultSupply),
    polygonTxns: useReducer(polygonTxnsReducer, defaultPolygonTxns),
    gawdtchis: useReducer(gawdtchiReducer, defaultGawdtchis),
    cultGame: useReducer(cultReducer, defaultCultGame)
  }

  return <StoreContext.Provider value={defaultGlobalStore}>{children}</StoreContext.Provider>
}

export default StoreProvider
