import { createContext, Dispatch, useMemo, useReducer, useState } from "react";
interface NonceData {
    isFetching?: boolean
    nonce?: number
}
const defaults: NonceData = {
    isFetching: false,
    nonce: 0
}

export type NonceActionType = 'update' | 'reset' | 'increment' | 'decrement' | 'latest'

const reducer = (state: NonceData, [action, payload]: [NonceActionType, NonceData?]): NonceData => {
    // console.info('nonce reducer', { action, state, payload })
    switch (action) {
        case 'latest':
            const nonce = payload.nonce > state.nonce ? payload.nonce : state.nonce
            return {
                ...state,
                nonce: nonce
            }

        case 'update':
            return {
                ...state,
                ...payload
            }

        case 'increment':
            return {
                ...state,
                ...payload,
                nonce: state.nonce + 1
            }

        case 'decrement':
            return {
                ...state,
                ...payload,
                nonce: state.nonce - 1
            }

        case 'reset':
            return defaults

        default:
            return state
    }
}

export const NonceContext = createContext<[NonceData, Dispatch<[NonceActionType, NonceData?]>]>(undefined);

export default function NonceProvider({ children }) {
    const value = useReducer(reducer, defaults)

    return (
        <NonceContext.Provider value={value}>
            {children}
        </NonceContext.Provider>
    )
}
