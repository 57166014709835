import { useCallback, useMemo } from "react";

export default function useEnvironment() {
  const isDev = useMemo(() => process.env.ENVIRONMENT != 'production', [])

  const log = useCallback((...args) => {
    if (isDev) {
      console.log(...args)
    }
  }, [isDev])

  const isBeta: boolean = process.env.IS_BLOOD_BETA == '1'
  return {
    isDev: isDev,
    isProd: process.env.ENVIRONMENT == 'production',
    isBeta: isBeta,
    isMainnetLive: isBeta, // it's the same thing, i know it's confusing
    log
  }
}